import React from 'react';
import { Button } from 'components/Buttons';
import { ColorDivider } from 'components/Dividers/Dividers';
import { SearchRedirectInput } from 'components/Inputs'

import './stylesheets/footer.scss';

type NavGroupProps = {
  header?: string;
  children: any;
  link?: string;
}

const FooterNavGroup = ({ header, children, link }: NavGroupProps): React.ReactElement => {
  const Wrapper = link ? 'a' : 'div';
  const wrapperProps = link ? { href: link } : {};

  return (
    <Wrapper {...wrapperProps}>
      {header && <h6>{header}</h6>}
      {children}
    </Wrapper>
  );
};

export const Footer = (): React.ReactElement => {
  const copyrightYear = new Date().getFullYear();

  return (
    <div className='footerWrapper'>
      <ColorDivider />
      <footer className='footer'>
        <div className='footer__header'>
          <div className='actions'>
            <SearchRedirectInput position='footer' />
            <Button
              text='START A FUND'
              customClassName='button--footer'
              link='https://www.cfengage.org/start-your-fund'
            />
          </div>
        </div>
        <div className='footer__body'>
          <div className='sub-nav-groups sub-nav-groups--border-right'>
            <FooterNavGroup
              header='DISCOVER'
              link='/discover'
            >
              <ul>
                <li><a href='/discover/community' className='button--footer-list'>Your Community</a></li>
                <li><a href='/discover/cf' className='button--footer-list'>Your Community Foundation</a></li>
                <li><a href='/discover/faces-of-philanthropy' className='button--footer-list'>Faces of Philanthropy</a></li>
                <li><a href='/blog' target="_blank" rel='noreferrer' className='button--footer-list'>What&apos;s New</a></li>
              </ul>
            </FooterNavGroup>
          </div>
          <div className='sub-nav-groups sub-nav-groups--vertical'>
            <FooterNavGroup
              header='DONATE'
            >
              <ul>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate' target='_blank' rel="noreferrer" className='button--footer-list'>Richmond Area Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1000' target='_blank' rel="noreferrer" className='button--footer-list'>Gloucester Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1001' target='_blank' rel="noreferrer" className='button--footer-list'>Mathews Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1002' target='_blank' rel="noreferrer" className='button--footer-list'>River Counties Funds</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header='ACCESS MY FUND'
            >
              <ul>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager' target='_blank' rel="noreferrer" className='button--footer-list'>Richmond CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1000' target='_blank' rel="noreferrer" className='button--footer-list'>Gloucester CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1001' target='_blank' rel="noreferrer" className='button--footer-list'>Mathews CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1002' target='_blank' rel="noreferrer" className='button--footer-list'>River Counties CF</a></li>
              </ul>
            </FooterNavGroup>
          </div>
        </div>
        <div className='footer__copyright'>
          <div>
            <h6>LOCATION</h6>
            <ul>
              <li><a href='https://www.google.com/maps/place/3409+W+Moore+St,+Richmond,+VA+23230' target='_blank' rel="noreferrer" className='button--footer-list'>3409 Moore Street<br /> Richmond, VA 23230</a></li>
            </ul>
          </div>
          <div>
            <h6>MAIL CHECKS TO:</h6>
            <ul>
              <li>Community Foundation for a greater Richmond <br /> P.O. Box 49044 <br /> Baltimore, MD 21297-9044</li>
            </ul>
          </div>
          <div>
            <h6>CONTACT US:</h6>
            <ul>
              <li>(804) 330-7400 <br /> <a href="mailto:info@cfrichmond.org">info@cfrichmond.org</a></li>
            </ul>
          </div>
          <div>
            <h6><a href='/policies'>CONDITIONS AND POLICIES</a></h6>
            <ul>
              <li>&copy; {copyrightYear} Community Foundation of Richmond. All rights reserved.</li>
            </ul>
          </div>
          <nav className='nav--social'>
            <ul>
              <li><Button icon='instagram' iconOnly={true} iconPrefix='fab' link='https://www.instagram.com/cfrichmondva/' /></li>
              <li><Button icon='facebook' iconOnly={true} iconPrefix='fab' link='https://www.facebook.com/cfrichmondva' /></li>
              <li><Button icon='linkedin' iconOnly={true} iconPrefix='fab' link='https://www.linkedin.com/company/the-community-foundation/' /></li>
              <li><Button icon='vimeo' iconOnly={true} iconPrefix='fab' link='https://vimeo.com/cfrichmondva' /></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  )
};
